import React from 'react';
import { CenteredHeader, CenteredSectionSmall, CenteredText, HomeBadge, PageContents } from '../Home/styled';
import { Hero, HeroContents, HeroBadge, HeroTitle, HeroDescription, DownIcon, GreyPlaceholder } from '../HowItWorks/styled';
import Header from '../../layout/Header';
import down from '../../images/down.svg';
import { Col, Row } from 'antd';
import { AboutPageData } from '../../models/common';
import { PRIMARY_COLOR } from '../../theme/constants';

interface Props {
	data: AboutPageData;
}

const AboutRoot: React.FC<Props> = (props) => {
	console.log(props);
	return (
		<React.Fragment>
			<Hero>
				<Header />
				<HeroContents>
					<HeroBadge>Our Vision</HeroBadge>
					<HeroTitle>About Us</HeroTitle>
					<HeroDescription>We are pleased to introduce to you the only data management asset tool you will ever need</HeroDescription>
					<DownIcon src={down} alt="Scroll Down" />
				</HeroContents>
			</Hero>
			<PageContents>
				<CenteredSectionSmall style={{ marginTop: 24 }}>
					<Row type="flex" align="middle" justify="center" gutter={24}>
						<Col md={10} sm={10} xs={24}>
							{/* <ImageContainer>
						<HomeImage src={home_example_4} alt="DeReader - Scan and View" />
						</ImageContainer> */}
							<GreyPlaceholder></GreyPlaceholder>
						</Col>
						<Col md={14} sm={14} xs={24}>
							<HomeBadge>Our Story</HomeBadge>
							<h2>A Message from our founders: Jim Williams and Lisa Balash</h2>
							<p>My business partner Lisa and I come from an oil and gas background with a combined experience of over 50 years. We started as welders and worked our way up through the inspection and quality aspects of our careers. We have both seen and experienced the difficulties of trying to complete a project, not having access to the information or having to purchase a new asset because the one you have on-site does not have paperwork.</p>
							<p>We have witnessed commissioning not being able to work as they are missing data sheets or manuals. We have seen companies have to spend extra costs and time to rebuild spools and purchase new assets just because they can not find the paperwork that is required by regulations before it can be installed.</p>
							<p>We figured there had to be a better way to not only save time but save costs due to down time while waiting to receive the information.</p>
						</Col>
					</Row>
				</CenteredSectionSmall>

				<CenteredSectionSmall>
					<HomeBadge>Our Vision</HomeBadge>
					<CenteredHeader>What we have in store</CenteredHeader>
					<CenteredText>My business partner and I come from an oil and gas background with a combined experience of over 50 years. We started as welders and worked our way up through the inspection and quality aspects of our careers. We have both seen and experienced the difficulties of trying to complete a project, not having access to the information or having to purchase a new asset because the one you have on site does not have paperwork.</CenteredText>
					<CenteredText>We have witnessed commissioning not being able to work as they are missing data sheets or manuals. We have seen companies have to spend extra costs and time to rebuild spools and purchase new assets just because they can not find the paperwork that is required by regulations before it can be installed.</CenteredText>
					<CenteredText style={{ fontWeight: "bold" }}>We figured there had to be a better way to not only save time but save costs due to down time while waiting to receive the information.</CenteredText>
				</CenteredSectionSmall>

				<CenteredSectionSmall>
					<HomeBadge>Our Process</HomeBadge>
					<CenteredHeader>What we have discovered</CenteredHeader>
					<CenteredText>In developing our app we figured out there is a huge need for this type of product. A product that can be used for a number of different public data storage and retrieval applications. If you have a product the public will want to be able to retrieve the information for, our app is for you.</CenteredText>
					<CenteredText>So we have come up with Dereader, why look for the paperwork when the information can now travel with the asset. Where it goes the paperwork goes too. Just point, scan and retrieve.</CenteredText>
				</CenteredSectionSmall>

				<CenteredSectionSmall style={{ marginBottom: 64 }}>
					<HomeBadge>Our Gratitude</HomeBadge>
					<CenteredHeader>Thank you for using DeReader</CenteredHeader>
					<CenteredText>Not only will this app save you time and money but it is also good for the environment. No need to have copies upon copies of paperwork. Now it’s all stored within the app and if you have multiple identical products you only have to enter the information once and print off the code for each piece. To retrieve the information it’s as simple as point and click. We at Dereader feel this app is perfect for all kinds of different applications and we feel our app can fulfill your asset management needs. Check us out. We at Dereader would like to take this time to thank you for using our app.</CenteredText>
					<CenteredText style={{ color: PRIMARY_COLOR }}>The DeReader Management Team.</CenteredText>
				</CenteredSectionSmall>
			</PageContents>
		</React.Fragment>
	);
};

export default AboutRoot;
