import React from 'react';
import Layout from '../layout/Layout';
import AboutRoot from '../views/About/AboutRoot';
import { graphql } from 'gatsby';
import { Seo } from '../components/Seo'

const ConnectPage = (props) => (
	<Layout>
		<Seo
			metaDescription={"We are pleased to introduce to you the only data management asset tool you will ever need"}
			title={"About"}
			slug={'/about'}
		/>
		<AboutRoot data={props.data} />
	</Layout>
);

export default ConnectPage;

export const query = graphql`
	query AboutPage {
		aboutPage: contentfulAboutPage {
			title
		}
	}
`;
